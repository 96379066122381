<template>
  <div class="product-images--upload">
    <vue-dropzone
      v-if="canEdit"
      ref="dropzone"
      id="dropzone"
      :options="dropzoneOptions"
      @vdropzone-complete="uploadImage"
      class="flex__center"
    ></vue-dropzone>
    <div class="tile-heading">
      <p class="text__bold">Existing Images</p>
      <hr />
    </div>
    <div class="flex__between">
      <p>Click and drag to reorder, click "done" when finished</p>
      <button class="btn btn__sm btn__frost" @click="updateProduct">Done</button>
    </div>
    <div v-if="form.images.length <= 0" class="flex__center product-images--default">
      <p class="text__bold">No images, upload one above</p>
    </div>
    <div v-else class="product-images__grid">
      <draggable v-model="form.images">
        <transition-group>
          <div class="product-images--image" v-for="img in form.images" :key="img">
            <img :src="img" alt="product image here" class="shadow" />
            <div class="btn__red text__center" @click="deleteImage(img)">
              <font-awesome-icon icon="times" size="1x" class="mr-10" />Delete
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { createError } from "../../../../libs/flash-messages";
import draggable from "vuedraggable";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001/api"
    : "https://wowapi.fbhl.com/api";

export default {
  props: ["canEdit", "product"],
  components: {
    draggable,
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${API_URL}/products/images/${this.product.name
          .replaceAll(/[^A-Za-z\d$\s]/g, "")
          .trim()
          .replaceAll(" ", "_")}`,
        thumbnailWidth: 350,
        maxFilesize: 15,
        maxFiles: 5,
        headers: {
          "X-AUTH-TOKEN": `Bearer ${
            JSON.parse(localStorage.getItem("user")).token
          }`,
          user: `${localStorage.getItem("user")}`
        },
        acceptedFiles: "image/*",
        dictDefaultMessage: "Drop image here to upload",
        addRemoveLinks: true
      },
      form: {
        _id: this.product ? this.product._id : null,
        images: this.product ? [...this.product.images] : []
      }
    };
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startUpdateProduct",
      "startDeleteImage"
    ]),
    async uploadImage(file) {
      if (file.status === "success") {
        this.form.images.push(...JSON.parse(file.xhr.response));
        this.startSetIsSubmitting({ bool: true, heading: "Uploading" });
        try {
          await this.startUpdateProduct(this.form);

          this.startSetIsSubmitting({ bool: false });
        } catch (err) {
          this.startSetIsSubmitting({ bool: false });
        }
      } else {
        createError("Upload failed", "Please refresh the page and try again.");
      }
    },
    async updateProduct() {
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        await this.startUpdateProduct(this.form);
        this.startSetIsSubmitting({ bool: false });
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    async deleteImage(link) {
      const file = link.split("https://cdn.fbhl.com/WOW-Products/")[1];
      this.startSetIsSubmitting({ bool: true, heading: "Deleting" });
      try {
        const res = await this.startDeleteImage(file);
        if (res === "success") {
          const form = {
            _id: this.form._id,
            images: this.form.images.filter(item => item !== link)
          };
          await this.startUpdateProduct(form);
          this.form = form;

          this.startSetIsSubmitting({ bool: false });
        } else {
          createError("Delete failed", "Please try again.");
        }
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>

<style lang="scss">
#dropzone {
  background-color: $frost;
  padding: 10px;
  height: 140px !important;
}
#dropzone .dz-preview .dz-image img {
  height: 120px !important;
  width: 120px !important;
}
.product-images--upload {
  @include grid($cols: 1fr, $row-gap: 10px, $col-gap: 25px);
}
.product-images--default {
  height: 100px;
  background-color: $frost;
}
.product-images__grid div span {
  @include flex($just: center, $align: center, $wrap: wrap);
}
.product-images--image {
  width: 150px;
  margin: 10px;
  @include flex($dir: column, $just: space-between, $align: center);
  img {
    width: 150px;
    height: 150px;
  }
  .btn__red {
    width: 100%;
  }
}
</style>