<template>
  <div class="margin__md">
    <div v-if="showEdit" class="flex__right">
      <button
        class="btn btn__sm btn__red mb-15"
        @click="toggleEditing"
      >{{ editing ? "Cancel" : "Edit"}}</button>
    </div>
    <ImgShow v-if="!editing" :product="product" @zoomImage="zoomImage" />
    <Zoom v-if="modalToggled" @toggleModal="toggleModal" :image="zoomLink" />
    <ImgUpload v-if="editing & canEdit" :canEdit="canEdit" :product="product" />
    <div v-if="editing && showEdit" class="flex__between mt-15">
      <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
      <button class="btn btn__sm btn__green" @click="toggleEditing">Finish</button>
    </div>
  </div>
</template>

<script>
import ImgShow from "./ImgShow";
import Zoom from "./Zoom";
import ImgUpload from "./ImgUpload";
export default {
  props: ["canEdit", "product"],
  components: {
    ImgShow,
    Zoom,
    ImgUpload
  },
  data() {
    return {
      editing: false,
      modalToggled: false,
      zoomLink: null
    };
  },
  computed: {
    route() {
      return this.$route.fullPath;
    },
    showEdit() {
      return this.route.includes("/admin");
    }
  },
  methods: {
    toggleEditing() {
      if (this.canEdit) {
        this.editing = !this.editing;
      }
      return;
    },
    toggleModal() {
      this.modalToggled = !this.modalToggled;
    },
    zoomImage(link) {
      this.zoomLink = link;
      this.modalToggled = !this.modalToggled;
    }
  }
};
</script>

<style scoped lang="scss">
</style>