<template>
  <div class="modal flex__center" @click="emitToggleModal">
    <div class="tile pos__rel pointer">
      <exit-button @toggleModal="emitToggleModal" />
      <img :src="image" alt="Product image" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["image"],
  methods: {
    emitToggleModal() {
      this.$emit("toggleModal", false);
    }
  }
};
</script>

<style scoped lang="scss">
img {
  width: 85vw;
  height: 85vw;
  max-width: 650px;
  max-height: 650px;
}
</style>