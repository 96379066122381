<template>
  <div class="img--magnify" @click="emitClicked">
    <font-awesome-icon icon="search-plus" size="lg" class="magnify-icon" />
  </div>
</template>

<script>
export default {
  methods: {
    emitClicked() {
      this.$emit("clicked");
    }
  }
};
</script>

<style scoped lang="scss">
.img--magnify {
  @include pos-abs(
    $pos: absolute,
    $top: null,
    $left: null,
    $bottom: 0,
    $right: 0,
    $z: 10,
    $width: 50px,
    $height: 50px
  );
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background-color: rgba(170, 170, 170, 0.75);
  color: $black;
  @include flex($just: flex-end, $align: flex-end);
}
.magnify-icon {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>