<template>
  <div class="product-images">
    <div class="product-images--main flex__center pos__rel">
      <img
        v-if="activeImage"
        :key="activeImage"
        :src="activeImage"
        :alt="`product image for ${product.name}`"
        @click="emitZoomImage(activeImage)"
        class="pointer"
        width="100%"
        height="100%"
      />
      <ZoomIcon v-if="activeImage" @clicked="emitZoomImage(activeImage)" />
      <p v-else class="text__bold text__center">No Image</p>
    </div>
    <div class="other--carousel">
      <div v-if="product.images.length <= 0" class="flex__center product-images--none">
        <p class="text__bold text__center">No Images</p>
      </div>
      <VueSlickCarousel v-else v-bind="settings">
        <div class="product-images--other pointer" v-for="img in product.images" :key="img">
          <img
            :src="img"
            @click="setActiveImage(img)"
            :alt="`product image for ${product.name}`"
            width="100%"
            height="100%"
          />
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import ZoomIcon from "./ZoomIcon";
export default {
  props: ["product"],
  components: { ZoomIcon, VueSlickCarousel },
  data() {
    return {
      activeImage: null,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        touchThreshold: 5,
        initialSlide: 0,
        focusOnSelect: false,
        vertical: true,
        verticalSwiping: true,
        responsive: [
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              vertical: false
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              vertical: false
            }
          },
          {
            breakpoint: 768,
            settings: {
              dots: true,
              slidesToShow: 3,
              slidesToScroll: 3,
              vertical: false,
              verticalSwiping: false
            }
          }
        ]
      }
    };
  },
  watch: {
    $route: function() {
      this.setBaseImg();
    }
  },
  methods: {
    setActiveImage(img) {
      this.activeImage = img;
    },
    emitZoomImage(link) {
      this.$emit("zoomImage", link);
    },
    setBaseImg() {
      this.activeImage = this.product.images[0];
    }
  },
  mounted() {
    this.setBaseImg();
  }
};
</script>

<style lang="scss">
.product-images {
  @include grid($cols: 100%, $rows: auto auto, $row-gap: 15px, $col-gap: 15px);
  grid-template-areas:
    "main"
    "other";
  width: 100%;
}
.product-images--main {
  grid-area: main;
  height: fit-content;
  background-color: $frost;
  /* img {
    height: 100%;
    width: 100%;
  } */
}
.product-images--other {
  grid-area: other;
  text-align: center;
  width: 100px !important;
  height: 100px;
  img {
    height: 80px;
    width: 80px;
    margin: 10px;
  }
}
.other--carousel .slick-carousel--container {
  padding: 0px;
}
.other--carousel .slick-slider {
  padding: 0px !important;
}
.other--carousel .slick-track {
  height: fit-content !important;
}
.other--carousel .slick-slide {
  padding: 0px !important;
  /* width: 125px !important; */
}
.other--carousel .slick-slide div {
  @include flex($just: center, $align: center);
}
.other--carousel button.slick-prev,
.other--carousel button.slick-next {
  width: 35px !important;
  height: 35px !important;
  /* display: none !important; */
}
.other--carousel button.slick-prev:before,
.other--carousel button.slick-next:before {
  color: $peacock !important;
  // background-color: $black !important;
  font-size: 1.75rem !important;
}
.other--carousel button.slick-next {
  right: -20px;
}
.other--carousel button.slick-prev {
  left: -22px;
}
@media (min-width: $md) {
  .product-images {
    grid-template-columns: 1fr 5fr;
    grid-template-rows: auto;
    grid-template-areas: "other main";
  }
  .other--carousel {
    height: 100%;
    max-height: 400px;
  }
  .other--carousel button.slick-next {
    bottom: -25px;
    top: unset;
    transform: rotate(90deg);
    right: calc(50% - 35px / 2);
  }
  .other--carousel button.slick-prev {
    top: -25px;
    transform: rotate(90deg);
    left: calc(50% - 35px / 2);
  }
}
</style>